import Phaser from 'phaser';

class Player {

    constructor(phaserScene, playerColor) {
        this.NONE = 0;
        this.UP = 1;
        this.DOWN = 2;
        this.LEFT = 3;
        this.RIGHT = 4;

        this.spriteFramesPerPlayer = 20;

        this.direction = [];
        this.lastDirection = this.NONE;

        this.phaserScene = phaserScene;
        this.playerColor = playerColor;


    }

    preload() {
      this.playerSpriteSheet = this.phaserScene.load.spritesheet('playerSprite', 'img/PlayerSprites.png',  { frameWidth: 24 , frameHeight: 24 });
    }

    create(collisionLayer) {
      this.keyInputs = this.phaserScene.input.keyboard.createCursorKeys();
      this.createAnimations();
      this.playerSprite = this.phaserScene.physics.add.sprite(100, 100, 'playerSprite');
      this.phaserScene.physics.add.collider(this.playerSprite, collisionLayer);
      this.playerSprite.anims.play("WaitDown", true);
      this.playerSprite.body.setSize(16,16).setOffset(5,7);
      //this.playerSprite.body.setCircle(8);
    }

    update() {
      this.setDirection();
      this.moveSprite();
      this.setSpriteAnimation();
    }

    setDirection() {
        if (this.keyInputs.left.isDown ) {
          if(this.direction.indexOf(this.LEFT) < 0) {
            this.direction.push(this.LEFT);
          }
        } else {
            let index = this.direction.indexOf(this.LEFT);
            if(index > -1) {
                this.direction.splice(index,1);
            }
        }

        if (this.keyInputs.up.isDown) {
          if(this.direction.indexOf(this.UP) < 0) {
            this.direction.push(this.UP);
          }
        } else {
            let index = this.direction.indexOf(this.UP);
            if(index > -1) {
                this.direction.splice(index,1);
            }
        }

        if (this.keyInputs.right.isDown) {
          if(this.direction.indexOf(this.RIGHT) < 0) {
            this.direction.push(this.RIGHT);
          }
        } else {
            let index = this.direction.indexOf(this.RIGHT);
            if(index > -1) {
                this.direction.splice(index,1);
            }
        }

        if (this.keyInputs.down.isDown) {
          if(this.direction.indexOf(this.DOWN) < 0) {
            this.direction.push(this.DOWN);
          }
        } else {
            let index = this.direction.indexOf(this.DOWN);
            if(index > -1) {
                this.direction.splice(index,1);
            }
        }
    }

    setSpriteAnimation() {
      if (this.direction.length > 0) {
        let currentDirection = this.direction[this.direction.length -1];
        if(currentDirection === this.UP) {
          this.playerSprite.anims.play("WalkUp", true);
        }
        else if(currentDirection === this.RIGHT) {
          this.playerSprite.anims.play("WalkRight", true);
        }
        else if(currentDirection === this.DOWN) {
          this.playerSprite.anims.play("WalkDown", true);
        }
        else if(currentDirection === this.LEFT) {
          this.playerSprite.anims.play("WalkLeft", true);
        }
        this.lastDirection = currentDirection;
      }
      else {
        if(this.lastDirection === this.UP) {
          this.playerSprite.anims.play("WaitUp", true);
        }
        else if(this.lastDirection === this.RIGHT) {
          this.playerSprite.anims.play("WaitRight", true);
        }
        else if(this.lastDirection === this.DOWN) {
          this.playerSprite.anims.play("WaitDown", true);
        }
        else if(this.lastDirection === this.LEFT) {
          this.playerSprite.anims.play("WaitLeft", true);
        }
      }
    }

    moveSprite() {
      this.playerSprite.body.setVelocity(0);
        if (this.direction.length > 0) {
            let currentDirection = this.direction[this.direction.length -1];
            if (currentDirection === this.LEFT ) {
                //this.playerSprite.x = this.playerSprite.x - 2;
              this.playerSprite.body.setVelocityX(-100);
            }
            else if (currentDirection === this.RIGHT) {
              this.playerSprite.body.setVelocityX(+100);
                //this.playerSprite.x = this.playerSprite.x + 2;
            }
            else if (currentDirection === this.UP) {
               //this.playerSprite.y = this.playerSprite.y - 2;
              this.playerSprite.body.setVelocityY(-100);
            }
            else if (currentDirection === this.DOWN) {
               // this.playerSprite.y = this.playerSprite.y + 2;
              this.playerSprite.body.setVelocityY(+100);
            }
        }
    }

    createAnimations() {
      let offset = this.playerColor * this.spriteFramesPerPlayer;
      this.phaserScene.anims.create({
        key: "WalkRight",
        frameRate: 10,
        frames: this.phaserScene.anims.generateFrameNumbers("playerSprite", {frames: [ offset+4,offset+3,offset+5,offset+3 ]}),
        repeat: -1
      });

      this.phaserScene.anims.create({
        key: "WalkDown",
        frameRate: 10,
        frames: this.phaserScene.anims.generateFrameNumbers("playerSprite", { frames: [ offset+1, offset+0, offset+2, offset+0 ] }),
        repeat: -1
      });

      this.phaserScene.anims.create({
        key: "WalkLeft",
        frameRate: 10,
        frames: this.phaserScene.anims.generateFrameNumbers("playerSprite", { frames: [ offset+7, offset+6, offset+8, offset+6 ] }),
        repeat: -1
      });

      this.phaserScene.anims.create({
        key: "WalkUp",
        frameRate: 10,
        frames: this.phaserScene.anims.generateFrameNumbers("playerSprite", { frames: [ offset+10, offset+9, offset+11, offset+9 ] }),
        repeat: -1
      });

      this.phaserScene.anims.create({
        key: "WaitRight",
        frameRate: 10,
        frames: this.phaserScene.anims.generateFrameNumbers("playerSprite", { frames: [ offset+3 ] }),
        repeat: -1
      });

      this.phaserScene.anims.create({
        key: "WaitDown",
        frameRate: 10,
        frames: this.phaserScene.anims.generateFrameNumbers("playerSprite", { frames: [ offset+0 ] }),
        repeat: -1
      });

      this.phaserScene.anims.create({
        key: "WaitLeft",
        frameRate: 10,
        frames: this.phaserScene.anims.generateFrameNumbers("playerSprite", { frames: [ offset+6 ] }),
        repeat: -1
      });

      this.phaserScene.anims.create({
        key: "WaitUp",
        frameRate: 10,
        frames: this.phaserScene.anims.generateFrameNumbers("playerSprite", { frames: [ offset+9 ] }),
        repeat: -1
      });
    }


}

module.exports = Player;






