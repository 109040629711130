'use strict'
import Phaser from 'phaser';
const Player = require('./PlayerMovement.js');

class FirstSeene extends Phaser.Scene
{
  constructor ()
  {
    super();
    this.player = new Player(this,0);
  }

  preload ()
  {
    this.player.preload();
    this.load.image('tiles', 'img/EnviromentSprites.png');
  }

  create ()
  {
    const level = [
        [26, 4, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 7, 19],
        [26, 16, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 8, 19],
        [26, 17, 0, 3, 0, 3, 0, 3, 0, 3, 0, 3, 0, 3, 0, 9, 19],
        [26, 18, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 10, 19],
        [26, 16, 0, 3, 0, 3, 0, 3, 0, 3, 0, 3, 0, 3, 0, 8, 19],
        [26, 16, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 8, 19],
        [26, 16, 0, 3, 0, 3, 0, 3, 0, 3, 0, 3, 0, 3, 0, 8, 19],
        [26, 16, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 8, 19],
        [26, 16, 0, 3, 0, 3, 0, 3, 0, 3, 0, 3, 0, 3, 0, 8, 19],
        [26, 17, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 9, 19],
        [26, 18, 0, 3, 0, 3, 0, 3, 0, 3, 0, 3, 0, 3, 0, 10, 19],
        [26, 16, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 8, 19],
        [27, 13, 13, 13, 13, 13, 13, 13, 13, 13, 13, 13, 13, 13, 13, 13, 20]
    ];
    this.map = this.make.tilemap({ data: level, tileWidth: 16, tileHeight: 16 });

    const tileset  = this.map.addTilesetImage('tiles', null, 16, 16, 0, 0);
    const layer = this.map.createLayer(0, tileset, -8, 0);
    this.map.setCollisionByExclusion([ 0, 1]);
    //this.map.setCollisionBetween(10, 30);
    this.player.create(layer);
  }

  update() {
    this.player.update();
  }

  render() {

    this.game.debug.text('FPS: ' + game.time.fps || 'FPS: --', 40, 40, "#00ff00");
  }
}


const config = {
  type:Phaser.WEBGL,
  width: '256',
  height: '208',
  pixelArt: true,
  zoom: 3,
  autoCenter: Phaser.Scale.CENTER_BOTH,
  parent: parentDiv,
  scene: FirstSeene,
  physics: {
    default: 'arcade',
    arcade: {
      tileBias: 4,
      //debug: true,
      gravity: { y: 0 }
    }
  }
}

const game = new Phaser.Game(config);
